import {
  TutorialStepWrapper,
  TutorialStepHeader,
  TutorialStepName,
  TutorialStepTitle,
  StepDescriptionWrapper,
  StepDescription,
  StepDescriptionColumn,
  StepIndicator,
  Anchor,
  Icon,
  NextStepButton
} from './styles'


export const TutorialStep = ({ nextStep, redirectLink, isUserLoggedIn, ...props }) => {
  const { name, title, description, buttonCopy, icon } = props

  const onClickButton = () => {
    if (nextStep) {
      nextStep()
    } else if (redirectLink) {
      window.location.href = redirectLink
    }
  }
  if (isUserLoggedIn) return 

  return <TutorialStepWrapper>
    <TutorialStepHeader>
      <Icon src={icon} />
      <TutorialStepName>{name}</TutorialStepName>
    </TutorialStepHeader>
    <TutorialStepTitle>{title}</TutorialStepTitle>
    {description}
    {(nextStep || redirectLink) && <NextStepButton onClick={onClickButton}>{buttonCopy}</NextStepButton>}
  </TutorialStepWrapper>
}

export const Step1 = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Register your kit here if you haven’t already. This is extremely important, since it’s going to  make sure you receive your resluts.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step2 = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Before you open the test kit, wash your hands thoroughly and dry them with a clean towel.
    </StepDescription>
    <StepDescription>
      Open the clear plastic bag and remove the sample card, ensuring you do not touch the circles on the test card. 
    </StepDescription>
    <StepDescription>
      Ensure no one other than you touches the card, and that the card is not touched again (even by you) after you’ve completed the test.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step3 = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      You will use the sample card to provide your saliva sample one of two ways:
    </StepDescription>
    <StepDescriptionColumn>
      <StepIndicator>Option 1</StepIndicator>
      <StepDescription>
        Open the clear plastic bag and remove the sample card, ensuring you do not touch the circles on the test card. 
      </StepDescription>
    </StepDescriptionColumn>

    <StepDescriptionColumn>
      <StepIndicator>Option 2</StepIndicator>
      <StepDescription>
        Ensure no one other than you touches the card, and that the card is not touched again (even by you) after you’ve completed the test.
      </StepDescription>
    </StepDescriptionColumn>
  </StepDescriptionWrapper>
}

export const Step4 = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      It’s imperative that you allow the sample card to dry completely before sealing it back up. This can take about 45 minutes, or sometimes longer.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step3DNA = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Open one of the swabs and use it to firmly scrape the inside of your left cheek. 
    </StepDescription>
    <StepDescription>
      As you rub the inside of your cheek, rotate the swab. 
    </StepDescription>
    <StepDescription>
      Continue for 45 seconds.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step4DNA = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Open one vial. Insert the swab, tip down, into the vial. 
    </StepDescription>
    <StepDescription>
      Align the black line on the swab with the top edge of the vial. Snap off the swab stick, leaving the swab tip in the vial, submerged in the liquid.
    </StepDescription>
    <StepDescription>
      Fasten the orange lid to secure the sample.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step5DNA = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Firmly scrape the inside of your right cheek, rotating the swab as you go, for 45 seconds.
    </StepDescription>
    <StepDescription>
      Place the swab in the vial, snapping the stick at the black line, and then fasten the orange lid.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step6DNA = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Ensuring the orange cap is secured on both vials, return the vials to the clear plastic bag.
    </StepDescription>
    <StepDescription>
      Seal the bag.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const Step7DNA = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Place the sealed plastic bag in the provided shipping envelope. 
    </StepDescription>
    <StepDescription>
      Seal the envelope and return the sample to us as soon as possible.
    </StepDescription>
  </StepDescriptionWrapper>
}

export const FinalStep = () => {
  return <StepDescriptionWrapper>
    <StepDescription>
      Once we have received your sample, we will process your test.
    </StepDescription>
    <StepDescription>
      Please remember: We can only send you your results if you have created your account and registered your kit.
    </StepDescription>
    <StepDescription>
      You can expect to receive your test results in 1 to 2 weeks.
    </StepDescription>
    <StepDescription>
      Have more questions? Refer to our frequently asked questions or teach out to us at <Anchor href="mailto:contact@trumelabs.com">contact@trumelabs.com</Anchor>.
    </StepDescription>
  </StepDescriptionWrapper>
}