import { useNavigate } from "react-router-dom";
import { OnboardingForm } from "../../components/onboarding-form";
import { ONBOARDING_FORM } from "../../constants/onboarding-form";
import logo from "../../images/logo-white.svg";
import api from "../../utils/api";

const Header = () => {

    return <div className="bg-white border-b-2 border-b-lightpurple flex flex-col justify-center h-[90px] px-[24px]">
        <img src={logo} alt='TrumeLabs' className="h-[60px] self-start" />
    </div>


}   


export const SignUpOnboardingDetails = () => {

    const navigate = useNavigate();

    const onEndForm = async (responses) => {
        await api.post('/user/demographic', responses);
        navigate('/dashboard')
    }

    return <>
        <OnboardingForm
        fields={ONBOARDING_FORM}
        onEndForm={onEndForm} />
    </>


}