import BoxIcon from '../../images/kit-tutorial-icons/box.svg'
import HandsIcon from '../../images/kit-tutorial-icons/hands.svg'
import LabIcon from '../../images/kit-tutorial-icons/lab.svg'
import DoneIcon from '../../images/kit-tutorial-icons/done.svg'
import RepeatIcon from '../../images/kit-tutorial-icons/repeat.svg'
import SealIcon from '../../images/kit-tutorial-icons/seal.svg'
import PackageIcon from '../../images/kit-tutorial-icons/package.svg'
import BoxCircleIcon from '../../images/kit-tutorial-icons/box-circle.svg'
import WaterCircleIcon from '../../images/kit-tutorial-icons/water-circle.svg'
import VialsCircleIcon from '../../images/kit-tutorial-icons/vials-circle.svg'
import CurveArrowsIcon from '../../images/kit-tutorial-icons/curve-arrows.svg'
import { VideoTutorial } from './video-tutorial'
import {
  TutorialStep,
  Step1,
  Step2,
  Step3DNA,
  Step4DNA,
  Step5DNA,
  Step6DNA,
  Step7DNA,
  FinalStep
} from './tutorial-steps'
import {
  TutorialHomePageWrapper,
  TutorialTitle,
  TutorialSubtitle,
  TutorialHomePageStep,
  Icon,
  HomePageStepText,
  HomePageStepDescription,
  NextStepButton,
  Anchor,
  KitSubmissionTutorialWrapper
} from './styles'



const TutorialHomePageDna = ({ nextStep, isUserLoggedIn }) => {
  return <TutorialHomePageWrapper>
    <TutorialTitle>
      Follow these instructions to collect the sample for your DNA Methylation Test.
    </TutorialTitle>
    <TutorialSubtitle>
      Before you begin...
    </TutorialSubtitle>
    {!isUserLoggedIn && <TutorialHomePageStep>
      <Icon src={BoxCircleIcon} />
      <HomePageStepText>Register your kit</HomePageStepText>
      <HomePageStepDescription><Anchor href="https://app.trumelabs.com/kit/number" target="_blank">Create your account and register your kit</Anchor> so you can receive your test results</HomePageStepDescription>
    </TutorialHomePageStep>}
    <TutorialHomePageStep>
      <Icon src={WaterCircleIcon} />
      <HomePageStepText>Hydrate</HomePageStepText>
      <HomePageStepDescription>Ensure your vial numbers are matching.</HomePageStepDescription>
    </TutorialHomePageStep>
    <TutorialHomePageStep>
      <Icon src={VialsCircleIcon} />
      <HomePageStepText>Check Vials</HomePageStepText>
      <HomePageStepDescription>Ensure your vial numbers are matching.</HomePageStepDescription>
    </TutorialHomePageStep>
    <NextStepButton onClick={nextStep}>
      Continue
    </NextStepButton>
  </TutorialHomePageWrapper>
}

export const DNAMethylationTestTestTutorial = ({ nextStep, divRefs, isUserLoggedIn }) => {
  const steps = [
    {
      component: <TutorialHomePageDna nextStep={() => nextStep(1)} isUserLoggedIn={isUserLoggedIn} />
    },
    {
      component: <VideoTutorial link="https://www.youtube.com/embed/8xH0YkdGwAA?si=LVZgWKCNMuo7LwND" nextStep={() => nextStep(2)} />
    },
    {
      component: <TutorialStep nextStep={() => nextStep(3)} isUserLoggedIn={isUserLoggedIn} { ...{
        icon: BoxIcon,
        name: 'Step 1',
        title: 'Register your Kit',
        description: <Step1 />,
        buttonCopy: 'Next step'
      }}/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(4)} { ...{
        icon: HandsIcon,
        name: !isUserLoggedIn ? 'Step 2' : 'Step 1',
        title: 'Wash your hands and begin',
        description: <Step2 />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(5)} { ...{
        icon: CurveArrowsIcon,
        name: !isUserLoggedIn ? 'Step 3' : 'Step 2',
        title: 'Swab your left cheek',
        description: <Step3DNA />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(6)} { ...{
        icon: LabIcon,
        name: !isUserLoggedIn ? 'Step 4' : 'Step 3',
        title: 'Place the sample in the vial',
        description: <Step4DNA />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(7)} { ...{
        icon: RepeatIcon,
        name: !isUserLoggedIn ? 'Step 5' : 'Step 4',
        title: 'Repeat Steps 3 and 4, now with your right cheek',
        description: <Step5DNA />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(8)} { ...{
        icon: SealIcon,
        name: !isUserLoggedIn ? 'Step 6' : 'Step 5',
        title: 'Seal the samples.',
        description: <Step6DNA />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep nextStep={() => nextStep(9)} { ...{
        icon: PackageIcon,
        name: !isUserLoggedIn ? 'Step 7' : 'Step 6',
        title: 'Prepare sample for return shipment.',
        description: <Step7DNA />,
        buttonCopy: 'Next step'}
      }/>
    },
    {
      component: <TutorialStep redirectLink="https://app.trumelabs.com" { ...{
        icon: DoneIcon,
        name: 'Done!',
        title: 'You’re done with your test! ',
        description: <FinalStep />,
        buttonCopy: 'Go to dashboard'}
      }/>
    }
  ]

  return steps.map((step, index) => (
    <KitSubmissionTutorialWrapper key={index} ref={el => divRefs.current[index] = el}>
      {step.component}
    </KitSubmissionTutorialWrapper>
  ))
}