import BoxIcon from '../../images/kit-tutorial-icons/box.svg'
import HandsIcon from '../../images/kit-tutorial-icons/hands.svg'
import LabIcon from '../../images/kit-tutorial-icons/lab.svg'
import ClockIcon from '../../images/kit-tutorial-icons/clock.svg'
import DoneIcon from '../../images/kit-tutorial-icons/done.svg'
import BoxCircleIcon from '../../images/kit-tutorial-icons/box-circle.svg'
import WaterCircleIcon from '../../images/kit-tutorial-icons/water-circle.svg'
import TimeCircleIcon from '../../images/kit-tutorial-icons/time-circle.svg'
import { VideoTutorial } from './video-tutorial'
import {
  TutorialStep,
  Step1,
  Step2,
  Step3,
  Step4,
  FinalStep
} from './tutorial-steps'
import {
  TutorialHomePageWrapper,
  TutorialTitle,
  TutorialSubtitle,
  TutorialHomePageStep,
  Icon,
  HomePageStepText,
  HomePageStepDescription,
  NextStepButton,
  Anchor,
  KitSubmissionTutorialWrapper
} from './styles'



const TutorialHomePageBiologicalAge = ({ nextStep, isUserLoggedIn }) => {
  return <TutorialHomePageWrapper>
    <TutorialTitle>
      Follow these instructions to collect the sample for your Biological Age Test.
    </TutorialTitle>
    <TutorialSubtitle>
      Before you begin...
    </TutorialSubtitle>
    {!isUserLoggedIn && <TutorialHomePageStep>
      <Icon src={BoxCircleIcon} />
      <HomePageStepText>Register your kit</HomePageStepText>
      <HomePageStepDescription><Anchor href="https://app.trumelabs.com/kit/number" target="_blank">Create your account and register your kit</Anchor> so you can receive your test results.</HomePageStepDescription>
    </TutorialHomePageStep>}
    <TutorialHomePageStep>
      <Icon src={WaterCircleIcon} />
      <HomePageStepText>Hydrate</HomePageStepText>
      <HomePageStepDescription>Make sure you’re well hydrated.</HomePageStepDescription>
    </TutorialHomePageStep>
    <TutorialHomePageStep>
      <Icon src={TimeCircleIcon} />
      <HomePageStepText>Wait after...</HomePageStepText>
      <HomePageStepDescription>Wait at least one hour after eating, smoking or brushing your teeth.</HomePageStepDescription>
    </TutorialHomePageStep>
    <NextStepButton onClick={nextStep}>
      Continue
    </NextStepButton>
  </TutorialHomePageWrapper>
}

export const BiologicalAgeTestTutorial = ({ nextStep, divRefs, isUserLoggedIn }) => {
  const steps = [
      {
        component: <TutorialHomePageBiologicalAge nextStep={() => nextStep(1)} isUserLoggedIn={isUserLoggedIn} />
      },
      {
        component: <VideoTutorial link="https://www.youtube.com/embed/gaWPryPrkao?si=0TDPqOsafndcwdG0" nextStep={() => nextStep(2)} />
      },
      {
        component: <TutorialStep nextStep={() => nextStep(3)} isUserLoggedIn={isUserLoggedIn} { ...{
          icon: BoxIcon,
          name: 'Step 1',
          title: 'Register your Kit',
          description: <Step1 />,
          buttonCopy: 'Next step'
        }}/>
      },
      {
        component: <TutorialStep nextStep={() => nextStep(4)} { ...{
          icon: HandsIcon,
          name: !isUserLoggedIn ? 'Step 2' : 'Step 1',
          title: 'Wash your hands and begin',
          description: <Step2 />,
          buttonCopy: 'Next step'}
        }/>
      },
      {
        component: <TutorialStep nextStep={() => nextStep(5)} { ...{
          icon: LabIcon,
          name: !isUserLoggedIn ? 'Step 3' : 'Step 2',
          title: 'Provide Sample',
          description: <Step3 />,
          buttonCopy: 'Next step'}
        }/>
      },
      {
        component: <TutorialStep nextStep={() => nextStep(6)} { ...{
          icon: ClockIcon,
          name: !isUserLoggedIn ? 'Step 4' : 'Step 3',
          title: 'Allow Time to Dry',
          description: <Step4 />,
          buttonCopy: 'Next step'}
        }/>
      },
      {
        component: <TutorialStep redirectLink="https://trumelabs.com" { ...{
          icon: DoneIcon,
          name: 'Done!',
          title: 'You’re done with your test! ',
          description: <FinalStep />,
          buttonCopy: 'Go to dashboard'}
        }/>
      }
    ]

    return steps.map((step, index) => (
      <KitSubmissionTutorialWrapper key={index} ref={el => divRefs.current[index] = el}>
        {step.component}
      </KitSubmissionTutorialWrapper>
    ))
}