import { useState } from 'react'
import styled from 'styled-components'
import { Dropdown } from '../defaults';



const FieldQuestionWithDropdown = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

const FieldDropdown = ({
  field,
  onUpdate,
  className
}) => {
  const [optionSelected, setOptionSelected] = useState(null);

  const onChange = (items) => {
    setOptionSelected(items[0].label)
    onUpdate(true, { [field.name]: items[0].label });
  }

  return <FieldQuestionWithDropdown className={`ml-3 ${className}`}>
      <Dropdown
        style={{ width: 300 }}
        placeholder='Select an option'
        values={optionSelected ? [{ label: optionSelected }] : []}
        onChange={onChange}
        options={field.options?.map(option => option.label)}
        searchable={false}
      />
    </FieldQuestionWithDropdown>
}

export default FieldDropdown